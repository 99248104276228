<template>
  <div class="min-h-screen my-10 sela-container">
    <div v-if="aboutUs" style="line-height: 2rem" v-html="aboutUs"></div>
    <img
      width="256"
      height="241"
      src="/images/sela.webp"
      class="w-72 mx-auto object-contain"
      :alt="t('sila')"
    />
  </div>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const aboutUs = await useGroupSettings(12, 'about_us')
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/about-us',
    t('about_sila'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)

useHead({
  title: t('about_sila'),
  link: [useSeoCanonical(url + '/about-us')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('about_sila'), url + '/about-us')
    ])
  ]
})
</script>
